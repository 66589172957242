import Header from "../components/Header"
import TestApi from "../components/TestApi";

export default function ForgotPasswordPage() {
    return (
        <div className="flex flex-col lg:items-center lg:justify-center h-screen">
          <h1 className="text-2xl lg:text-8xl font-bold text-gray-800 mb-11">Forgot Password?</h1>
          <h2 className="text-xl lg:text-6xl font-bold text-gray-800 mb-11">忘记密码?</h2>
          <p className="text-xl lg:text-4xl text-gray-600 lg:mb-11 lg:mt-11">
            Please contact <a href="mailto:xingzhexin@outlook.com" className="text-blue-600 hover:text-blue-800">xingzhexin@outlook.com</a> for password reset.
          </p>
          <p className="text-xl lg:text-4xl text-gray-600 mb-11">
            请联系 <a href="mailto:xingzhexin@outlook.com" className="text-blue-600 hover:text-blue-800">xingzhexin@outlook.com</a> 重置密码
          </p>
          <p className="text-xl lg:text-4xl text-gray-600 lg:mt-11">
            Already have an account? 已有账户? <a href="/" className="text-blue-600 hover:text-blue-800">登录 Login in</a>
          </p>
          <p className="text-xl lg:text-4xl text-gray-600 lg:mt-11">
            这是老爸的奇怪页面 <a href="/StaticTestPage" className="text-blue-600 hover:text-blue-800">点击跳转</a>
          </p>
          <TestApi />
        </div>
      );
}