import Header from "../components/Header"
import Login from "../components/Login"
import SideBar from "../components/SideBar"
import CenterFormLayout from "../components/CenterFormLayout"

export default function LoginPage() {
    return (
        <div className="flex flex-col md:flex-row min-h-screen">
            <div className="w-full md:w-16 md:h-screen md:order-1 order-2">
                <SideBar />
            </div>
            <div className="flex flex-col md:w-full items-center justify-center md:order-2 order-1">
                <Header
                    heading="登录到您的账户"
                    paragraph="没有账户?"
                    linkName="立即创建 Sign up"
                    linkUrl="/signup"
                />
                <Login />
            </div>
        </div>
    )
}
