import React, { useEffect, useState } from 'react';

function TestApi() {
    const [data, setData] = useState(null);

    useEffect(() => {
        console.log("Heey")
        fetch('/api/test/')
            .then(response => response.json())
            .then(data => setData(data));
    }, []);

    if (!data) return "Loading...";

    return <div>{data.message}</div>;
}

export default TestApi;
