import { BsPlus, BsFillLightningFill } from 'react-icons/bs'
import { FaAd, FaAnchor, FaFire, FaPoo } from 'react-icons/fa';

const SideBar = () => {
    return (
        <div className="h-full w-full flex md:flex-col flex-row bg-gray-900 text-white shadow-lg">
            <SideBarIcon icon={<FaFire size="28"/>} text="HAHA FIRRRRRRRE"/>
            <SideBarIcon icon={<BsPlus size="32"/>} text="This is button 02"/>
            <SideBarIcon icon={<BsFillLightningFill size="20"/>} text="This is button 03" />
            <SideBarIcon icon={<FaAnchor size="20"/>} text="HAHA! Anchor!" />
        </div>
    )
}

const SideBarIcon = ({ icon, text = 'tooltip 💡' }) => (
    <div className="sidebar-icon group">
        {icon}
        <span className="sidebar-tooltip group-hover:scale-100">{text}</span>
    </div>
);

export default SideBar