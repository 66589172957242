import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import SignupPage from './pages/Signup';
import LoginPage from './pages/Login';
import ForgotPasswordPage from './pages/ForgotPassword';
import StaticTestPage from './pages/StaticTestPage';



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="/StaticTestPage" element={<StaticTestPage/>} />
        <Route path="*" element={<h1>Not Found 404</h1>} />
      </Routes>
    </BrowserRouter>
  );
}


export default App;
