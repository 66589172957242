import Header from "../components/Header"
import Signup from "../components/Signup"
import SideBar from "../components/SideBar"

export default function SignupPage() {
    return (
        <div className="flex flex-col md:flex-row min-h-screen">
            <div className="w-full md:w-16 md:h-screen md:order-1 order-2">
                <SideBar />
            </div>
            <div className="flex flex-col md:w-full items-center justify-center md:order-2 order-1">
            <Header
                heading="创建新的账户"
                paragraph="已有账户?"
                linkName="立即登录 Sign in now!"
                linkUrl="/"
            />
            <Signup />
        </div>
        </div >
    )
}