import React from 'react';

const StaticHtmlComponent = () => {
    // Your HTML code as a string
    const htmlContent = `
    <style>
  .subscribe-button{
    background-color: rgb(200, 0,0);
    color: white;
    border:none;
    height: 36px;
    width: 105px;
    border-radius: 2px;
    cursor: pointer;
    margin-left: 8px;
    transition: opacity 0.15s;
  }

  .subscribe-button:hover {
    opacity: 0.8;

  }

  .subscribe-button:active {

    opacity: 0.5;
  }


  .join-button {
    background-color: white;
    border-color: rgb(41, 118, 211);
    border-style:solid;
    border-width: 1px;
    color: rgb(41, 118, 211);
    height: 36px;
    width: 60px;
    border-radius: 2px;
    cursor: pointer;
    margin-left: 8px;
    transition: background-color 0.15s, color 0.15s;

  }

  .join-button:hover {
    background-color: rgb(41, 118, 211);
    color: white;
  }

  .join-button:active {
    opacity: 0.7;

  }

  .tweet-button {

    background-color: rgb(2, 137, 255);
    color: white; 
    border: none;
    height: 36px;
    width: 70px;
    border-radius: 18px;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    margin-left: 8px;
    transition: box-shadow 0.15s;

  }

  .tweet-button:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    
  }
</style>

<button class="subscribe-button">SUBSCRIBE</button>
<button class="join-button">
  JOIN
</button>

<button class="tweet-button">
  Tweet
</button>
    `;

    return (
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
};

export default StaticHtmlComponent;
